import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';

export const FOOTER_MIN_HEIGHT = 545;

export const FooterRootWrapper = styled.footer`
  display: grid;
  padding: 16px;
  width: 100%;
  background-color: ${COLOR.WHITE};
  min-height: ${FOOTER_MIN_HEIGHT}px;
  border-top: 1px solid ${COLOR.GRAY200};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

export const FooterNavigation = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const FooterList = styled.li`
  + li {
    padding-left: 8px;
    border-left: 1px solid ${COLOR.BLACK90};
  }
`;

export const AnchorLink = styled.a`
  color: ${COLOR.BLACK90};
`;

export const UnderLineAnchorLink = styled.a`
  color: ${COLOR.BLACK90};
  text-decoration: underline;
`;

export const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled.p`
  font-size: ${FONT_SIZE.SIZE_16};

  color: ${COLOR.BLACK90};
  @media (max-width: 728px) {
    font-size: ${FONT_SIZE.SIZE_14};
  }
`;
