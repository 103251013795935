import { ANOTHER_PATHS } from '@/consts/another-path';
import { PATHS } from '@/consts/path';

export const INTERNAL_LINKS = [
  { href: PATHS.MEDIA_COMMON.MEDIA_TOP, label: 'TOP' },
  { href: PATHS.MEDIA_COMMON.ARTICLES, label: '記事一覧' },
  { href: PATHS.MEDIA_COMMON.TAGS_PAGE, label: 'タグ一覧' },
  { href: PATHS.SUPERVISOR, label: '監修者メッセージ' },
] as const;

export const OTHER_LINKS = [
  { href: PATHS.TERMS, isExternal: false, label: '利用規約' },
  {
    href: PATHS.PRIVACY_POLICY,
    isExternal: false,
    label: 'プライバシーポリシー',
  },
  { href: ANOTHER_PATHS.COMPANY, isExternal: true, label: '運営会社' },
  { href: PATHS.CONTACT, isExternal: false, label: 'お問い合わせ' },
  { href: PATHS.FAQ, isExternal: false, label: 'よくある質問' },
  { href: PATHS.EDIT_POLICY, isExternal: false, label: '編集ポリシー' },
  {
    href: PATHS.HOW_TO_USE,
    isExternal: false,
    label: 'らくらく履歴書の使い方',
  },
] as const;
