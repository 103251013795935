import Link from 'next/link';
import { memo } from 'react';

import { Spacer } from '@/components/atoms/spacer';
import { IconRakurakuLogo } from '@/components/icons/rakuraku-logo';
import { ANOTHER_PATHS } from '@/consts/another-path';
import { Axis } from '@/consts/axis';
import { MINISTRY_OF_HEALTH_URL, ANN_EMPLOYMENT_AGENCY_URL } from '@/consts/outsource-url';
import { PATHS } from '@/consts/path';
import { RESUME_TITLE } from '@/consts/resume-title';
import { getSpacing } from '@/consts/spacingUnit';
import { useSelectCv } from '@/hooks/use-select-cv';
import { useSelectResume } from '@/hooks/use-select-resume';

import type { Category } from '@/mediaApi/types';

import { INTERNAL_LINKS, OTHER_LINKS } from './const';
import { SectionTitle } from './section-title';
import * as Styled from './style';

type Props = {
  categories?: Category[];
};

const _Footer = ({ categories }: Props) => {
  const { setCvPreferences } = useSelectCv();
  const { setResumeAndPreferences } = useSelectResume();

  const RESUME_LINKS = [
    {
      href: PATHS.RESUME_LINKS.JIS_RESUME,
      label: RESUME_TITLE.JIS_RESUME_TITLE,
      setPreferences: () => setResumeAndPreferences('JIS_RESUME_TITLE'),
    },
    {
      href: PATHS.RESUME_LINKS.MINISTRY_OF_HEALTH_RESUME,
      label: RESUME_TITLE.MINISTRY_OF_HEALTH_RESUME_TITLE,
      setPreferences: () => setResumeAndPreferences('MINISTRY_OF_HEALTH_RESUME_TITLE'),
    },
    {
      href: PATHS.RESUME_LINKS.JOB_CHANGE_RESUME,
      label: RESUME_TITLE.JOB_CHANGE_TITLE,
      setPreferences: () => setResumeAndPreferences('JOB_CHANGE_TITLE'),
    },
    {
      href: PATHS.RESUME_LINKS.NEW_GRADUATE_RESUME,
      label: RESUME_TITLE.NEW_GRADUATE_TITLE,
      setPreferences: () => setResumeAndPreferences('NEW_GRADUATE_TITLE'),
    },
    {
      href: PATHS.RESUME_LINKS.PART_TIME_RESUME,
      label: RESUME_TITLE.PART_TIME_TITLE,
      setPreferences: () => setResumeAndPreferences('PART_TIME_TITLE'),
    },
    {
      href: PATHS.RESUME_LINKS.CV,
      label: RESUME_TITLE.CV_RESUME_TITLE,
      setPreferences: () => setCvPreferences('CV_RESUME_TITLE', 'COMMON'),
    },
  ] as const;

  return (
    <Styled.FooterRootWrapper>
      <Styled.FooterContainer>
        <SectionTitle title='履歴書・職務経歴書を作成する' topSpacing={0} bottomSpacing={1} />

        <Styled.FooterNavigation>
          {RESUME_LINKS.map(({ label, href, setPreferences }, index) => (
            <Styled.FooterList key={index}>
              {/* TODO: nextjsのversion更新時に内部のaタグを削除する */}
              <Link href={href} passHref>
                <Styled.AnchorLink onClick={setPreferences}>{label}</Styled.AnchorLink>
              </Link>
            </Styled.FooterList>
          ))}
        </Styled.FooterNavigation>

        <SectionTitle title='記事カテゴリ' topSpacing={3} bottomSpacing={1} />

        <Styled.FooterNavigation>
          {categories &&
            categories.map((category) => (
              <Styled.FooterList key={category.id}>
                <Styled.AnchorLink href={`/media/categories/${category.slug}`}>{category.name}</Styled.AnchorLink>
              </Styled.FooterList>
            ))}
        </Styled.FooterNavigation>

        <SectionTitle title='サイトコンテンツ' topSpacing={3} bottomSpacing={1} />

        <Styled.FooterNavigation>
          {INTERNAL_LINKS.map((link, index) => (
            <Styled.FooterList key={index}>
              <Link href={link.href}>{link.label}</Link>
            </Styled.FooterList>
          ))}
        </Styled.FooterNavigation>

        <SectionTitle title='その他' topSpacing={3} bottomSpacing={1} />

        <Styled.FooterNavigation>
          {OTHER_LINKS.map(({ isExternal, href, label }) => (
            <Styled.FooterList key={href}>
              {isExternal ? (
                <Styled.AnchorLink href={href} target='_blank'>
                  {label}
                </Styled.AnchorLink>
              ) : (
                <Link href={href}>{label}</Link>
              )}
            </Styled.FooterList>
          ))}
        </Styled.FooterNavigation>

        <SectionTitle title='関連サービス' topSpacing={3} bottomSpacing={1} />

        <Styled.FooterNavigation>
          <Styled.FooterList>
            <Styled.AnchorLink href={ANOTHER_PATHS.SHUKATSU_TIMES} target='_blank'>
              就活タイムズ
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            <Styled.AnchorLink href={ANOTHER_PATHS.NENSHU_CHECKER} target='_blank'>
              年収チェッカー
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            <Styled.AnchorLink href={ANOTHER_PATHS.CAREER_MINE} target='_blank'>
              CareerMine就活
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            SPI対策アプリ（
            <Styled.AnchorLink href={ANOTHER_PATHS.SPI_IOS} target='_blank'>
              iOS版
            </Styled.AnchorLink>
            ・{' '}
            <Styled.AnchorLink href={ANOTHER_PATHS.SPI_ANDROID} target='_blank'>
              Android版
            </Styled.AnchorLink>
            ）
          </Styled.FooterList>
          <Styled.FooterList>
            <Styled.AnchorLink href={ANOTHER_PATHS.SPI_WEB} target='_blank'>
              SPI対策問題集
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            面接練習アプリ（
            <Styled.AnchorLink href={ANOTHER_PATHS.INTERVIEW_IOS} target='_blank'>
              iOS版
            </Styled.AnchorLink>
            ・{' '}
            <Styled.AnchorLink href={ANOTHER_PATHS.INTERVIEW_ANDROID} target='_blank'>
              Android版 ）
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            時事問題対策アプリ（
            <Styled.AnchorLink href={ANOTHER_PATHS.NEWS_APP_IOS} target='_blank'>
              iOS版
            </Styled.AnchorLink>
            ・{' '}
            <Styled.AnchorLink href={ANOTHER_PATHS.NEWS_APP_ANDROID} target='_blank'>
              Android版 ）
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            ES添削アプリ（
            <Styled.AnchorLink href={ANOTHER_PATHS.ES_FIX_IOS} target='_blank'>
              iOS版
            </Styled.AnchorLink>
            ・{' '}
            <Styled.AnchorLink href={ANOTHER_PATHS.ES_FIX_ANDROID} target='_blank'>
              Android版 ）
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            <Styled.AnchorLink href={ANOTHER_PATHS.ES_MAKER} target='_blank'>
              ES Maker
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            CareerMine（
            <Styled.AnchorLink href={ANOTHER_PATHS.CAREER_MINE_IOS} target='_blank'>
              iOS版
            </Styled.AnchorLink>
            ・{' '}
            <Styled.AnchorLink href={ANOTHER_PATHS.CAREER_MINE_ANDROID} target='_blank'>
              Android版 ）
            </Styled.AnchorLink>
          </Styled.FooterList>
          <Styled.FooterList>
            らくらく履歴書（
            <Styled.AnchorLink href={ANOTHER_PATHS.RAKURAKU_RIREKISHO_IOS} target='_blank'>
              iOS版
            </Styled.AnchorLink>
            ・{' '}
            <Styled.AnchorLink href={ANOTHER_PATHS.RAKURAKU_RIREKISHO_ANDROID} target='_blank'>
              Android版 ）
            </Styled.AnchorLink>
          </Styled.FooterList>
        </Styled.FooterNavigation>

        <Spacer size={getSpacing(3)} axis={Axis.VERTICAL} />
        <Styled.TextWrapper>
          らくらく履歴書は、有料職業紹介事業者として、
          <Styled.UnderLineAnchorLink href={MINISTRY_OF_HEALTH_URL} target='_blank'>
            厚生労働大臣
          </Styled.UnderLineAnchorLink>
          の許可を得たAnn株式会社が運営をしています。
        </Styled.TextWrapper>
        <Spacer axis={Axis.VERTICAL} size={8} />
        <Styled.TextWrapper>
          職業紹介事業詳細は
          <Styled.UnderLineAnchorLink href={ANN_EMPLOYMENT_AGENCY_URL} target='_blank'>
            厚生労働省職業安定局のページ
          </Styled.UnderLineAnchorLink>
          から確認することができます。
        </Styled.TextWrapper>

        <Spacer size={getSpacing(4)} axis={Axis.VERTICAL} />
        <Styled.FooterBottom>
          <IconRakurakuLogo width={getSpacing(16)} height={getSpacing(4)} />
          <div> &copy; 2023 Ann.Inc</div>
        </Styled.FooterBottom>
      </Styled.FooterContainer>
    </Styled.FooterRootWrapper>
  );
};

export const Footer = memo(_Footer);
