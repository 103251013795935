import styled from '@emotion/styled';

import { FONT_SIZE } from '@/styles/font-size';

export const FooterSectionTitle = styled.p`
  font-size: ${FONT_SIZE.SIZE_20};
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: ${FONT_SIZE.SIZE_12};
  }
`;
