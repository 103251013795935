export const ANOTHER_PATHS = {
  /** 就活タイムズ TOPページ */
  SHUKATSU_TIMES: 'https://shukatsu-times.jp/',
  /** 年収チェッカー TOPページ */
  NENSHU_CHECKER: 'https://www.nenshu-checker.com/media',
  /** CareerMine就活 TOPページ */
  CAREER_MINE: 'https://www.careermine.jp',
  /** SPI対策問題集 TOPページ */
  SPI_WEB: 'https://spi.careermine.jp',
  /** SPI対策アプリ（iPhone版） */
  SPI_IOS:
    'https://apps.apple.com/jp/app/spi%E5%AF%BE%E7%AD%96-web%E3%83%86%E3%82%B9%E3%83%88%E8%A8%80%E8%AA%9E-%E9%9D%9E%E8%A8%80%E8%AA%9E%E5%95%8F%E9%A1%8C%E9%9B%86/id1594343044',
  /** SPI対策アプリ（Android版） */
  SPI_ANDROID: 'https://play.google.com/store/apps/details?id=inc.ann.spi_taisaku',
  /** 面接練習アプリ（iPhone版）） */
  INTERVIEW_IOS:
    'https://apps.apple.com/jp/app/%E9%9D%A2%E6%8E%A5%E7%B7%B4%E7%BF%92%E3%82%A2%E3%83%97%E3%83%AA-%E5%B0%B1%E6%B4%BB%E5%AF%BE%E7%AD%96%E3%81%AB%E6%9C%80%E9%81%A9-%E8%B3%AA%E5%95%8F%E9%9B%86%E3%81%A8%E3%81%97%E3%81%A6%E3%82%82%E4%BD%BF%E3%81%88%E3%82%8B/id1629388755',
  /** 面接練習アプリ（Android版）） */
  INTERVIEW_ANDROID: 'https://play.google.com/store/apps/details?id=inc.ann.interview_app',
  /** 時事問題＆一般常識対策アプリ（iPhone版） */
  NEWS_APP_IOS:
    'https://apps.apple.com/jp/app/%E6%99%82%E4%BA%8B%E5%95%8F%E9%A1%8C-%E4%B8%80%E8%88%AC%E5%B8%B8%E8%AD%98-2022-%E5%B0%B1%E6%B4%BB%E3%81%AE%E7%AD%86%E8%A8%98%E8%A9%A6%E9%A8%93-%E9%9D%A2%E6%8E%A5%E5%AF%BE%E7%AD%96%E3%82%A2%E3%83%97%E3%83%AA/id1639833941',
  /** 時事問題＆一般常識対策アプリ（Android版） */
  NEWS_APP_ANDROID: 'https://play.google.com/store/apps/details?id=inc.ann.news',
  /** ES添削アプリ（iPhone版） */
  ES_FIX_IOS:
    'https://apps.apple.com/jp/app/es%E6%B7%BB%E5%89%8A-%E9%9D%A2%E6%8E%A5%E5%AF%BE%E7%AD%96-%E3%82%A8%E3%83%B3%E3%83%88%E3%83%AA%E3%82%B7%E3%83%BC%E3%83%88%E3%81%AE%E6%B7%BB%E5%89%8A%E3%81%8B%E3%82%89%E5%B0%B1%E6%B4%BB%E9%9D%A2%E6%8E%A5%E5%AF%BE%E7%AD%96/id1667756722',
  /** ES添削アプリ（Android版） */
  ES_FIX_ANDROID: 'https://play.google.com/store/apps/details?id=inc.ann.career_chat',
  /** ES Maker */
  ES_MAKER: 'https://es.careermine.jp/maker',
  /** CareerMine（iPhone版） */
  CAREER_MINE_IOS:
    'https://apps.apple.com/jp/app/%E5%A4%A9%E8%81%B7%E8%A8%BA%E6%96%AD-%E6%9C%AC%E5%BD%93%E3%81%AE%E5%8F%AF%E8%83%BD%E6%80%A7%E3%81%AB%E6%B0%97%E3%81%A5%E3%81%8F%E8%BB%A2%E8%81%B7%E5%B0%B1%E8%81%B7-%E6%B1%82%E4%BA%BA-%E4%BB%95%E4%BA%8B%E6%8E%A2%E3%81%97%E3%82%A2%E3%83%97%E3%83%AA/id6450152388',
  /** CareerMine（Android版） */
  CAREER_MINE_ANDROID: 'https://play.google.com/store/apps/details?id=inc.ann.career_mine',
  /** 運営会社ページ */
  COMPANY: 'https://ann.inc/',
  /** プライバシーポリシーページ */
  PRIVACY_POLICY: 'https://ann.inc/privacypolicy',
  /** シャープネットワークプリント利用規約ページ */
  SHARP_PRINT_TERMS: 'https://networkprint.ne.jp/Lite/terms.html',
  /** シャープネットワークプリントコピー機の操作方法ページ */
  SHARP_PRINT_USAGE: 'https://networkprint.ne.jp/sharp_netprint/ja/howto.aspx#howto_print',
  /** お問い合わせページ */
  CONTACT: 'https://docs.google.com/forms/d/e/1FAIpQLScYz02XZFjSHTkbHeTA3kW40SwKpWw9o0O1xpLQMbA06hAP4A/viewform',
  /** ファミリーマートの印刷料金ページ */
  FAMILY_MART: 'https://networkprint.ne.jp/sharp_netprint/ja/howto_price_f.aspx',
  /** ローソングループの印刷料金ページ */
  LAWSON: 'https://networkprint.ne.jp/sharp_netprint/ja/howto_price_l.aspx',
  /** ポプラグループの印刷料金ページ */
  POPURA: 'https://networkprint.ne.jp/sharp_netprint/ja/howto_price_p.aspx',
  /** らくらく履歴書（iOS） */
  RAKURAKU_RIREKISHO_IOS:
    'https://apps.apple.com/jp/app/%E3%82%89%E3%81%8F%E3%82%89%E3%81%8F%E5%B1%A5%E6%AD%B4%E6%9B%B8-%E5%B0%B1%E6%B4%BB-%E8%BB%A2%E8%81%B7-%E3%82%A2%E3%83%AB%E3%83%90%E3%82%A4%E3%83%88%E3%81%AB-%E8%81%B7%E5%8B%99%E7%B5%8C%E6%AD%B4%E6%9B%B8%E3%82%82%E5%AF%BE%E5%BF%9C/id6502155797?platform=iphone',
  /** らくらく履歴書（Android） */
  RAKURAKU_RIREKISHO_ANDROID: 'https://play.google.com/store/apps/details?id=inc.ann.rakuraku_rirekisho_app&pli=1',
} as const;
