import { Spacer } from '@/components/atoms/spacer';
import { Axis } from '@/consts/axis';
import { getSpacing } from '@/consts/spacingUnit';

import * as Styled from './style';

type Props = {
  title: string;
  topSpacing: number;
  bottomSpacing: number;
};

export const SectionTitle = ({ title, topSpacing, bottomSpacing }: Props) => (
  <>
    <Spacer size={getSpacing(topSpacing)} axis={Axis.VERTICAL} />
    <Styled.FooterSectionTitle>{title}</Styled.FooterSectionTitle>
    <Spacer size={getSpacing(bottomSpacing)} axis={Axis.VERTICAL} />
  </>
);
